var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('page-title-block',{attrs:{"store-name":"Custom","page-name":"Regional Overview"}}),_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"3","cols":"12"}},[_c('StatisticCard',{attrs:{"title":"Total Picks","statistic":_vm.totalPicks,"units":"orders"}})],1),_c('v-col',{attrs:{"sm":"6","md":"3","cols":"12"}},[_c('StatisticCard',{attrs:{"title":"Total Orders","statistic":_vm.totalConsignments,"units":"orders"}})],1),_c('v-col',{attrs:{"sm":"6","md":"3","cols":"12"}},[_c('StatisticCard',{attrs:{"title":"Total Collections","statistic":_vm.totalCollections,"units":"orders"}})],1),_c('v-col',{attrs:{"sm":"6","md":"3","cols":"12"}},[_c('StatisticCard',{attrs:{"title":"Stores Offline","statistic":("" + (_vm.mappedValues.length - _vm.totalOnline)),"units":"stores"}})],1),_c('v-col',{attrs:{"sm":"6","md":"3","cols":"12"}},[_c('StatisticCard',{attrs:{"title":"Total Time Offline","statistic":_vm._f("offlineTimeFormatter")(_vm.totalTimeOffline),"units":_vm._f("offlineTimeFormatter")(_vm.totalTimeOffline,true),"progress":_vm.percentageTimeOffline}})],1),_c('v-col',{attrs:{"sm":"6","md":"3","cols":"12"}},[_c('StatisticCard',{attrs:{"title":"Total Ave Pick Time","statistic":_vm._f("integerFormat")(_vm.totalAveragePickTime),"units":"mins"}})],1)],1),_c('v-data-table',{attrs:{"items":_vm.mappedValues,"headers":_vm.headers,"items-per-page":-1,"sort-by":"onlineStatus"},scopedSlots:_vm._u([{key:"item.onlineStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.onlineStatus === 'Online' ? 'green' : 'red',"dark":""}},[_vm._v(_vm._s(item.onlineStatus))])]}},{key:"item.timeOffline",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("offlineTimeFormatter")(item.timeOffline))+" "+_vm._s(_vm._f("offlineTimeFormatter")(item.timeOffline,true))+" ")]}},{key:"item.storeName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/" + (item.storeId) + "/store-details")}},[_vm._v(_vm._s(item.storeName))])]}},{key:"item.lastOnlineStatusChange",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("longDate")(item.lastOnlineStatusChange))+" ")]}},{key:"item.stats.pickTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("integerFormat")(item.stats.pickTime))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }