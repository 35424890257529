<template>
    <v-container>
      <page-title-block store-name="Custom" page-name="Regional Overview"></page-title-block>
      <v-row>
        <v-col sm="6" md="3" cols="12">
          <StatisticCard
            title="Total Picks"
            :statistic="totalPicks"
            units="orders"
          ></StatisticCard>
        </v-col>
        <v-col sm="6" md="3" cols="12">
          <StatisticCard
            title="Total Orders"
            :statistic="totalConsignments"
            units="orders"
          ></StatisticCard>
        </v-col>
        <v-col sm="6" md="3" cols="12">
          <StatisticCard
            title="Total Collections"
            :statistic="totalCollections"
            units="orders"
          ></StatisticCard>
        </v-col>
        <v-col sm="6" md="3" cols="12">
          <StatisticCard
            title="Stores Offline"
            :statistic="`${mappedValues.length - totalOnline}`"
            units="stores"
          ></StatisticCard>
        </v-col>
        <v-col sm="6" md="3" cols="12">
          <StatisticCard
            title="Total Time Offline"
            :statistic="totalTimeOffline | offlineTimeFormatter"
            :units="totalTimeOffline | offlineTimeFormatter(true)"
            :progress="percentageTimeOffline"
          ></StatisticCard>
        </v-col>
        <v-col sm="6" md="3" cols="12">
          <StatisticCard
            title="Total Ave Pick Time"
            :statistic="totalAveragePickTime | integerFormat"
            units="mins"
          ></StatisticCard>
        </v-col>
      </v-row>

      <v-data-table
        :items="mappedValues"
        :headers="headers"
        :items-per-page="-1"
        sort-by="onlineStatus"
      >
        <template v-slot:[`item.onlineStatus`]="{ item }">
          <v-chip :color="item.onlineStatus === 'Online' ? 'green' : 'red'" dark>{{ item.onlineStatus }}</v-chip>
        </template>
        <template v-slot:[`item.timeOffline`]="{ item }">
          {{ item.timeOffline  | offlineTimeFormatter }} {{ item.timeOffline  | offlineTimeFormatter(true) }}
        </template>
        <template v-slot:[`item.storeName`]="{ item }">
          <router-link :to="`/${item.storeId}/store-details`">{{item.storeName}}</router-link>
        </template>
        <template v-slot:[`item.lastOnlineStatusChange`]="{ item }">
          {{ item.lastOnlineStatusChange | longDate }}
        </template>
        <template v-slot:[`item.stats.pickTime`]="{ item }">
          {{ item.stats.pickTime | integerFormat }}
        </template>
      </v-data-table>
    </v-container>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import StatisticCard from '../components/StatisticCard'
import PageTitleBlock from '@/components/PageTitleBlock'

export default {
  name: 'FreeformRegionDetailsView',
  components: { PageTitleBlock, StatisticCard },
  filters: {
    offlineTimeFormatter: (time, suffix) => {
      const timeArray = moment.duration(time, 'minutes').humanize().split(' ')
      return suffix ? timeArray.slice(1).join(' ') : timeArray[0]
    },
    offlineSince: (value) => {
      if (!value) return ''
      const time = moment(value)
      if (time.dayOfYear() === moment().dayOfYear()) {
        return 'Since ' + time.format('HH:mm')
      } else {
        return 'Since ' + time.format('ddd HH:mm')
      }
    },
    integerFormat: (value) => {
      if (isNaN(value)) {
        return 0
      } else {
        return Number(value).toFixed(0)
      }
    }
  },
  data () {
    return {
      loading: false,
      error: null,
      timer: '',
      headers: [
        {
          text: 'Store Name',
          value: 'storeName'
        },
        {
          text: 'Pick Queue',
          value: 'pickQueue'
        },
        {
          text: 'Picks Today',
          value: 'picks'
        },
        {
          text: 'Orders Today',
          value: 'stats.consignmentCreation'
        },
        {
          text: 'Collections Today',
          value: 'collections'
        },
        {
          text: 'Ave Pick Time',
          value: 'stats.pickTime'
        },
        {
          text: 'Online Status',
          value: 'onlineStatus'
        },
        {
          text: 'Upper Threshold',
          value: 'upperThreshold'
        },
        {
          text: 'Lower Threshold',
          value: 'lowerThreshold'
        },
        {
          text: 'Last Online Status Change',
          value: 'lastOnlineStatusChange'
        },
        {
          text: 'Time Offline',
          value: 'timeOffline'
        }
      ]
    }
  },
  computed: {
    ...mapState('instore', ['stores', 'regionDetails', 'stores']),
    storeIds: function () {
      const storeIds = this.$route.query.storeIds
      if (Array.isArray(storeIds)) {
        return storeIds
      } else if (storeIds.indexOf(',' > -1)) {
        return storeIds.split(',')
      } else {
        return [storeIds]
      }
    },
    mappedValues: function () {
      return this.regionDetails.map(item => ({
        ...item,
        timeOffline: (function () {
          if (item.onlineStatus === true) {
            return item.timeOffline
          } else {
            // the store is offline, so calculate the time it went offline and add the hours since then
            const lastChangeWasToday = moment(item.lastOnlineStatusChange).isSame(moment(), 'day')
            const timeWentOffline = lastChangeWasToday ? moment(item.lastOnlineStatusChange) : moment.tz('Europe/London').startOf('day')
            const timeSinceLastChange = moment.tz('Europe/London').diff(timeWentOffline, 'minutes')
            return item.timeOffline + timeSinceLastChange
          }
        }()),
        onlineStatus: item.onlineStatus ? 'Online' : 'Offline',
        storeName: this.stores.filter(x => x.storeId === item.storeId)[0].storeName
      }))
    },
    totalPicks: function () {
      return this.regionDetails ? this.regionDetails.map(store => Number(store.picks || 0)).reduce((x, y) => x + y, 0) : 0
    },
    totalCollections: function () {
      return this.regionDetails ? this.regionDetails.map(store => Number(store.collections || 0)).reduce((x, y) => x + y, 0) : 0
    },
    totalConsignments: function () {
      return this.regionDetails ? this.regionDetails.map(store => Number(store.stats.consignmentCreation || 0)).reduce((x, y) => x + y, 0) : 0
    },
    totalAveragePickTime: function () {
      return (this.regionDetails ? this.regionDetails.map(store => Number(store.stats.pickTime || 0)).reduce((x, y) => x + y, 0) : 0) / this.regionDetails.length
    },
    totalTimeOffline: function () {
      return this.mappedValues ? this.mappedValues.map(store => store.timeOffline).reduce((x, y) => x + y, 0) : 0
    },
    numberOfStoresInRegion: function () {
      return this.mappedValues.length || 0
    },
    percentageTimeOffline: function () {
      // TODO complete
      const midnight = moment.tz('Europe/London').startOf('day')
      const totalNumberOfMinsPossible = moment.tz('Europe/London').diff(midnight, 'minutes')
      console.log('Max online mins today', totalNumberOfMinsPossible * this.numberOfStoresInRegion)
      return (this.totalTimeOffline / (totalNumberOfMinsPossible * this.numberOfStoresInRegion)) * 100
    },
    totalOnline: function () {
      return this.regionDetails ? this.regionDetails.map(store => store.onlineStatus === true ? 1 : 0).reduce((x, y) => x + y, 0) : 0
    }
  },
  created () {
    this.fetchData()
    this.timer = setInterval(this.fetchData, 30000)
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData'
  },
  methods: {
    fetchData: async function () {
      this.error = null
      this.loading = true
      const token = await this.$auth.getTokenSilently()
      this.$store.dispatch('instore/loadRegionDetails', { storeIds: this.storeIds, token })
        .then(() => (this.loading = false))
        .catch(err => (this.error = err))
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>

</style>
